import $ from 'jquery';

$(function(){
    modalToggle('area')
    modalToggle('industry')
    modalToggle('feature')
});

const modalToggle = (type) => {
    const open = $('.modalOpen__' + type),
        close = $('.modalBody__close'),
        modal = $('.modal__' + type);

    open.on('click', function(){
        modal.addClass('active');
        return false;
    });

    close.on('click', function(){
        modal.removeClass('active');
    });

    $(document).on('click', function(e) {
        if(!$(e.target).closest('.modalBody').length) {
            modal.removeClass('active');
        }
    });
}

window.HOME = {}

HOME.toggleCities = (prefectureId) => {
    const prefectureElement = $('#prefecture_' + prefectureId);
    const iconElement = $('#prefecture_' + prefectureId + ' i')

    if (prefectureElement.attr('class').includes('active')) {
        prefectureElement.removeClass('active')
        iconElement.removeClass('fa-chevron-up')
        iconElement.addClass('fa-chevron-down')
    } else {
        prefectureElement.addClass('active')
        iconElement.removeClass('fa-chevron-down')
        iconElement.addClass('fa-chevron-up')
    }
}

